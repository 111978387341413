import { useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import OrderDetailContainer from '~/src/components/orders/OrderDetailContainer'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { Constants } from '~/src/helpers/constants'
import { useFetchOrderDetail } from '~/src/hooks/orders/useFetchOrderDetail'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { orderIsPickedUp } from '~/src/services/OrderService'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { ORDER_DETAILS_STATE } from '~/src/stores/orders'
import useGetOrderTemplate from '~/src/components/orders/order-layout/styles/useGetOrderTemplate'
import InPageLoader from '~/src/components/loader/InPageLoader'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import SlideFromBottom from '~/src/components/mui-wrappers/animations/motion/SlideFromBottom'

const Tracker = () => {
  const { pathname } = useLocation()
  const firstFetch = useRef<boolean>(true)
  const { orderId } = useParams()
  const [ orderDetail, setOrderDetails ] = useEoState(ORDER_DETAILS_STATE)
  const customer = useEoValue(CUSTOMER_STATE)
  const { fetch: fetchOrderDetail, loading: isFetchingOrderDetail } = useFetchOrderDetail()
  const interval = useRef<NodeJS.Timer>()

  useGetOrderTemplate()

  const _getIsLoading = (): boolean => {
    return isFetchingOrderDetail || firstFetch.current || !orderId
  }

  useEffect(() => {
    return () => {
      clearInterval(interval.current!)
      setOrderDetails(null)
    }
  }, [])

  useAsyncEffect(async () => {
    clearInterval(interval.current!)

    if (!orderDetail) {
      await fetchOrderDetail(orderId, customer, true)
    }

    if (orderDetail && !orderIsPickedUp(orderDetail.status)) {
      interval.current = setInterval(async () => {
        await fetchOrderDetail(orderId, customer, false)
      }, Constants.FETCH_ORDER_DETAIL_INTERVAL)
    }

    firstFetch.current = false
  }, [ customer, orderId, orderDetail?.id ])

  return (
    <>
      <DocumentTitleInjector pageName={'TRACKER'}/>

      {_getIsLoading() && <InPageLoader sx={{ mt: 0 }}/>}
      {!_getIsLoading() && (
        <SlideFromBottom
          pathname={pathname}
          y={10}>
          <ContentContainer sx={{ mb: 2 }}>
            <OrderDetailContainer orderSummary={orderDetail}/>
          </ContentContainer>
        </SlideFromBottom>
      )}
    </>
  )
}

export default Tracker
